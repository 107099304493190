import React, { useState } from 'react';
import { connectAPI } from "../helpers/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { validerFormConnexion } from '../helpers/validationFormConnexion.js';
import { SessionData } from "../helpers/SessionData";
import { Modal, ModalBody } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Connexion() {

    const navigate = useNavigate()

    const [user, setUser] = useState('');
    const [mdp, setMdp] = useState('');
    const [visibilite, setVisibilite] = useState('none');
    const [erreurUser, setErreurUser] = useState('');
    const [erreurMDP, setErreurMDP] = useState('');
    const [connect, setConnect] = useState(false);
    const [emailMDPOublie, setEmailMDPOublie] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [erreurMDPOublie, setErreurMDPOublie] = useState('');
    const [spinnerVisible, setSpinnerVisible] = useState('d-none');

    const erreursInitiales = () => {
        setErreurUser('');
        setErreurMDP('');
        setVisibilite('none');
    };

    const handleUserChange = event => {
        setUser(event.target.value);
        erreursInitiales();
    };

    const handleMDPChange = event => {
        setMdp(event.target.value);
        erreursInitiales();
    };

    const handleEmailMDPOublieChange = event => {
        setEmailMDPOublie(event.target.value);
        setErreurMDPOublie('');
    };

    const handleSubmit = event => {
        event.preventDefault();

        const validation = validerFormConnexion(user, mdp);

        if (!validation.isValid) {
            setErreurUser(validation.user);
            setErreurMDP(validation.mdp);
        } else {
            connexion();
        }
    };

    const handleSubmitMDPOublie = event => {
        event.preventDefault();

        const emailregex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";

        if (emailMDPOublie === "") {
            setErreurMDPOublie("Veuillez entrer un email");
        } else if (!emailMDPOublie.trim().match(emailregex)) {
            setErreurMDPOublie("Veuillez entrer un email valide");
        } else {
            envoyerEmailMDPOublie();
        }
    };

    const connexion = async () => {
        const data = new FormData();
        data.append('connection_info', user);
        data.append('password', mdp);

        const result = await connectAPI("check_login.php", data);

        if (result.data == null || result.data === "nullfailed verify") {
            setVisibilite('');
        } else {
            const user = {
                email: result.data.email,
                prenom: result.data.firstName,
                idUser: result.data.idUser,
                nom: result.data.lastName,
                matricule: result.data.matricule,
                tel: result.data.phoneNumber,
                st: result.data.sessionToken
            };
            SessionData().setUserInfos(user);

            window.open("https://livres-usages.agectr.com", "_self");
            // window.open("https://dev.agectr.com", "_self");
        }
    };

    const envoyerEmailMDPOublie = async () => {
        setSpinnerVisible("d-block");

        const data = new FormData();
        const pass = genererMDP();

        data.append('mail', emailMDPOublie);
        data.append('password', pass);

        const result = await connectAPI("reset_password.php", data);

        setSpinnerVisible("d-none");

        if (result.data.succes === true) {
            alert("Email envoyé avec succès.");
            setIsOpen(false);
        } else {
            alert("L'email que vous avez entré ne correspond pas. Veuillez vérifier votre email et réessayer.");
        }
    };

    const genererMDP = () => {
        let p = '';
        const ALPHA_NUMERIC_STRING = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        for (let i = 0; i < 8; i++) {
            const character = parseInt(Math.random() * ALPHA_NUMERIC_STRING.length);
            p += ALPHA_NUMERIC_STRING.charAt(character);
        }
        return p;
    };

    const openModal = event => {
        event.preventDefault();
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const toInscription = event => {
        event.preventDefault();
        navigate('/inscription');
    };

    return (
        <div>
            <div className="container-fluid ">
                <div className="row pt-sm-5">
                    <div className="col-12 col-sm-5 col-md-7 col-lg-4 col-xl-4 col-xxl-5 mx-auto primaire rounded-3 shadow p-5 text-center">
                        <h1 className="text-center text-white">Connexion</h1>
                        <hr className="col-8 border border-primary border-3 mx-auto" />
                        <h3 className="text-center text-warning" style={{ display: `${visibilite}` }}>Nom d'utilisateur ou mot de passe incorrecte</h3>

                        <form className="mt-5" onSubmit={handleSubmit} noValidate>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <FontAwesomeIcon icon={faUser} className="texte-primaire" size="2x" />
                                </span>
                                <input type="text" className="form-control" placeholder="Matricule / Courriel" onChange={handleUserChange} required aria-label="Matricule / Courriel" aria-describedby="basic-addon1" />
                                <div className="text-start form-text col-12 text-warning">{erreurUser}</div>
                            </div>
                            <div className="input-group mb-4">
                                <span className="input-group-text" id="basic-addon2">
                                    <FontAwesomeIcon icon={faLock} className="texte-primaire" size="2x" />
                                </span>
                                <input type="password" className="form-control" placeholder="Mot de passe" onChange={handleMDPChange} required aria-label="Mot de passe" aria-describedby="basic-addon2" />
                                <div className="form-text text-start col-12 text-warning">{erreurMDP}</div>
                            </div>

                            <div className=" mb-3 text-center">
                                <button type="submit" id="envoyer" className='btn btn-primary text-white btn-lg col-12'>Se connecter</button>
                            </div>
                        </form>

                        <div className="row ">
                            <div className="col-12 text-center">
                                <Link to="/inscription" className=" text-white text-decoration-none fs-4">Inscription</Link>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-12">
                                <a href="#" onClick={openModal} className="text-white text-decoration-none">Mot de passe oublié ?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Header>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="text-end">
                                <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <h3>Mot de passe oublié ?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4 className={spinnerVisible}><FontAwesomeIcon icon={faSpinner} spin size="1x" /></h4>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <ModalBody>
                    <form className="mt-1" onSubmit={handleSubmitMDPOublie} noValidate>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Entrez votre email</label>
                            <input type="email" className="form-control" placeholder="ex: jean.masse@email.com" onChange={handleEmailMDPOublieChange} required />
                            <div className="text-start form-text col-12 text-danger">{erreurMDPOublie}</div>
                        </div>
                        <div className=" mb-3 text-center">
                            <button type="submit" id="envoyerMDPOublie" className='btn btn-primary text-white btn-lg col-12'>Envoyer</button>
                        </div>
                        <div className=" mb-3 text-center">
                            Vous pouvez également nous appeler 1-819-376-1721 poste 2524 ou vous rendre à notre local au Pavillon des Humanités HA-1152
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Connexion;
